<template>
    <v-container fluid class="home" :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-8'">

        <div v-if="this.$store.getters.userDetails.name == '' || loading" class="pt-12">

            <v-row no-gutters>
                <v-col cols="12" justify="center" align="center" class="pt-12">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>

            </v-row>

        </div>
        <div v-else class="pt-6">

            <v-row no-gutters class="pb-12">
                <v-col cols="6" md="3" class="pa-4">
                    <v-card class="soft-shadow">
                        <v-card-text class="pb-0">
                            <v-row no-gutters>
                                <v-col>
                                    <div class="font-weight-medium grey--text">Users</div>
                                </v-col>
                                <v-col> <v-icon large color="primary" class="d-flex justify-end">
                                        mdi-account-group
                                    </v-icon></v-col>
                            </v-row>

                            <div class="font-weight-medium text-h6 pt-2">{{ total_users.toLocaleString() }}</div>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text color="primary" @click="report('users')">VIEW</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="6" md="3" class="pa-4">
                    <v-card class="soft-shadow">
                        <v-card-text class="pb-0">
                            <v-row no-gutters>
                                <v-col>
                                    <div class="font-weight-medium grey--text">Pets</div>
                                </v-col>
                                <v-col> <v-icon large color="primary" class="d-flex justify-end">
                                        mdi-paw
                                    </v-icon></v-col>
                            </v-row>
                            
                            <div class="font-weight-medium text-h6 pt-2">{{ active_pets.toLocaleString() }} <span class="text-caption">({{ total_pets.toLocaleString() }})</span></div>
                  

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text color="primary" @click="report('pets')">VIEW</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="6"  md="3" class="pa-4">
                    <v-card class="soft-shadow">
                        <v-card-text class="pb-0">

                            <v-row no-gutters>
                                <v-col>
                                    <div class="font-weight-medium grey--text">Messages</div>
                                </v-col>
                                <v-col> <v-icon large color="primary" class="d-flex justify-end">
                                        mdi-email
                                    </v-icon></v-col>
                            </v-row>

                            
                            <div class="font-weight-medium text-h6 pt-2">&nbsp;</div>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text color="primary" @click="report('messages')">VIEW</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="6" md="3" class="pa-4">
                    <v-card class="soft-shadow">
                        <v-card-text class="pb-0">
                            <v-row no-gutters>
                                <v-col>
                                    <div class="font-weight-medium grey--text">Premium</div>
                                </v-col>
                                <v-col> <v-icon large color="primary" class="d-flex justify-end">
                                        mdi-star
                                    </v-icon></v-col>
                            </v-row>
                      
                            <div class="font-weight-medium text-h6 pt-2">{{ total_premium.toLocaleString() }}</div>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small text color="primary" @click="report('subscriptions')">VIEW</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>

            </v-row>





            <v-row no-gutters>
                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3 class="mb-2">Users</h3>
                    <v-simple-table dense>
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Users</th>
                            <th class="text-left font-weight-medium white--text">US</th>
                            <th class="text-left font-weight-medium white--text">IN</th>
                            <th class="text-left font-weight-medium white--text">UK</th>
                            <th class="text-left font-weight-medium white--text">CA</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">AU</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in users"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('users',item.total, index, 'total')">{{ item.total }} </td>
                            <td class="text-caption" :class="getCompClass('users',item.us_users, index, 'us_users')">{{ item.us_users }}</td>
                            <td class="text-caption" :class="getCompClass('users',item.in_users, index, 'in_users')">{{ item.in_users }}</td>
                            <td class="text-caption" :class="getCompClass('users',item.uk_users, index, 'uk_users')">{{ item.uk_users }}</td>
                            <td class="text-caption" :class="getCompClass('users',item.ca_users, index, 'ca_users')">{{ item.ca_users }}</td>
                            <td class="text-caption" :class="getCompClass('users',item.au_users, index, 'au_users')">{{ item.au_users }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <!-- <apexchart type="line" :options="users_options" :series="users_series"></apexchart> -->
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3 class="mb-2">Categories</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Users</th>
                            <th class="text-left font-weight-medium white--text">Adopters</th>
                            <th class="text-left font-weight-medium white--text">Buyers</th>
                            <th class="text-left font-weight-medium white--text">Breeders</th>
                            <th class="text-left font-weight-medium white--text">Mates</th>
                            <th class="text-left font-weight-medium white--text">Individuals</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Shelters</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in categories"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.total, index, 'total')">{{ item.total }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.adopters, index, 'adopters')">{{ item.adopters }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.buyers, index, 'buyers')">{{ item.buyers }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.breeders, index, 'breeders')">{{ item.breeders }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.mate_seekers, index, 'mate_seekers')">{{ item.mate_seekers }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.individuals, index, 'individuals')">{{ item.individuals }}</td>
                            <td class="text-caption" :class="getCompClass('categories',item.shelters, index, 'shelters')">{{ item.shelters }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <!-- <apexchart type="line" :options="users_options" :series="users_series"></apexchart> -->
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3 class="mb-2">Verified</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Email</th>
                            <th class="text-left font-weight-medium white--text">Phone</th>
                            <th class="text-left font-weight-medium white--text">Name</th>
                            <th class="text-left font-weight-medium white--text">Photo</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Video</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in verified"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('verified',item.user_email_verified, index, 'user_email_verified')">{{ item.user_email_verified }}</td>
                            <td class="text-caption" :class="getCompClass('verified',item.user_phone_verified, index, 'user_phone_verified')">{{ item.user_phone_verified }} ({{ item.has_phone }})</td>
                            <td class="text-caption" :class="getCompClass('verified',item.user_name_verified, index, 'user_name_verified')">{{ item.user_name_verified }}</td>
                            <td class="text-caption" :class="getCompClass('verified',item.user_photo_verified, index, 'user_photo_verified')">{{ item.user_photo_verified }}</td>
                            <td class="text-caption" :class="getCompClass('verified',item.user_video_verified, index, 'user_video_verified')">{{ item.user_video_verified }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <!-- <apexchart type="line" :options="users_options" :series="users_series"></apexchart> -->
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3 class="mb-2">Adopters</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Adopters</th>
                            <th class="text-left font-weight-medium white--text">Pending</th>
                            <th class="text-left font-weight-medium white--text">Submitted</th>
                            <th class="text-left font-weight-medium white--text">Verified</th>
                            <th class="text-left font-weight-medium white--text">Video</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">V.verified</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in adopters"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('adopters',item.category_adopter_buyer, index, 'category_adopter_buyer')">{{ item.category_adopter_buyer }}</td>
                            <td class="text-caption" :class="getCompClass('adopters',item.pending_status, index, 'pending_status')">{{ item.pending_status }}</td>
                            <td class="text-caption" :class="getCompClass('adopters',item.submitted_status, index, 'submitted_status')">{{ item.submitted_status }}</td>
                            <td class="text-caption" :class="getCompClass('adopters',item.verified_status, index, 'verified_status')">{{ item.verified_status }}</td>
                            <td class="text-caption" :class="getCompClass('adopters',item.video_uploaded, index, 'video_uploaded')">{{ item.video_uploaded }}</td>
                            <td class="text-caption" :class="getCompClass('adopters',item.video_verified, index, 'video_verified')">{{ item.video_verified }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <!-- <apexchart type="line" :options="users_options" :series="users_series"></apexchart> -->
                </v-col>

                

                <!-- <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3>Country</h3>
                    <apexchart type="bar" :options="country_options" :series="country_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3>User Emails</h3>
                    <apexchart type="line" :options="users_options" :series="user_emails_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'users'">
                    <h3>User Phone</h3>
                    <apexchart type="line" :options="users_options" :series="user_phone_series"></apexchart>
                </v-col> -->

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3 class="mb-2">Pets</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Total</th>
                            <th class="text-left font-weight-medium white--text">Dogs</th>
                            <th class="text-left font-weight-medium white--text">Cats</th>
                            <th class="text-left font-weight-medium white--text">Rabbits</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Rodents</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in pets_by_day"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.total_pets, index, 'total_pets')">{{ item.total_pets }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.dogs, index, 'dogs')">{{ item.dogs }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.cats, index, 'cats')">{{ item.cats }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.rabbits, index, 'rabbits')">{{ item.rabbits }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.rodents, index, 'rodents')">{{ item.rodents }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3 class="mb-2">Pets Purpose</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Total</th>
                            <th class="text-left font-weight-medium white--text">Breeding</th>
                            <th class="text-left font-weight-medium white--text">Sale</th>
                            <th class="text-left font-weight-medium white--text">Adoption</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Friendship</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in pets_by_day"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.total_pets, index, 'total_pets')">{{ item.total_pets }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.lovers, index, 'lovers')">{{ item.lovers }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.buyers, index, 'buyers')">{{ item.buyers }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.adopters, index, 'adopters')">{{ item.adopters }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.friends, index, 'friends')">{{ item.friends }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>


                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3 class="mb-2">Pets Verifications</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Name</th>
                            <th class="text-left font-weight-medium white--text">Description</th>
                            <th class="text-left font-weight-medium white--text">Pedigree</th>
                            <th class="text-left font-weight-medium white--text">DNA</th>
                            <th class="text-left font-weight-medium white--text">Video</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Extra Info</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in pets_by_day"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.pet_name_verified, index, 'pet_name_verified')">{{ item.pet_name_verified }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.pet_description_verified, index, 'pet_description_verified')">{{ item.pet_description_verified }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.pet_pedigree_verified, index, 'pet_pedigree_verified')">{{ item.pet_pedigree_verified }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.pet_dna_verified, index, 'pet_dna_verified')">{{ item.pet_dna_verified }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.pet_video_verified, index, 'pet_video_verified')">{{ item.pet_video_verified }}</td>
                            <td class="text-caption" :class="getCompClass('pets_by_day',item.pet_extra_info_verified, index, 'pet_extra_info_verified')">{{ item.pet_extra_info_verified }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

                <!-- <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3>Pets </h3>
                    <apexchart type="bar" :options="pets_options" :series="pets_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3>Purpose</h3>
                    <apexchart type="line" :options="purpose_options" :series="purpose_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3>Profile Video</h3>
                    <apexchart type="line" :options="video_options" :series="profile_video_series"></apexchart>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3>DNA Report</h3>
                    <apexchart type="line" :options="dna_options" :series="dna_reports_series"></apexchart>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'pets'">
                    <h3>Pedigree Certificate</h3>
                    <apexchart type="line" :options="pedigree_options" :series="pedigree_certificate_series"></apexchart>
                </v-col> -->

                <!-- <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'messages'">
                    <h3>Messages</h3>
                    <apexchart type="line" :options="messages_options" :series="messages_series"></apexchart>
                </v-col> -->

                <v-col cols="12" class="pa-4" v-if="this.category == 'messages'">
                    <h3 class="mb-2">Messages Per Category (read)</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Total</th>
                            <th class="text-left font-weight-medium white--text">Adopters</th>
                            <th class="text-left font-weight-medium white--text">Buyers</th>
                            <th class="text-left font-weight-medium white--text">Breeders</th>
                            <th class="text-left font-weight-medium white--text">Mates</th>
                            <th class="text-left font-weight-medium white--text">Individuals</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Shelters</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in messages_by_category"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.total, index, 'total')">{{ item.total }} ({{ item.total_read }})</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.adopters, index, 'adopters')">{{ item.adopters }} ({{ item.adopters_read }})</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.buyers, index, 'buyers')">{{ item.buyers }} ({{ item.buyers_read }})</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.breeders, index, 'breeders')">{{ item.breeders }} ({{ item.breeders_read }})</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.mate_seekers, index, 'mate_seekers')">{{ item.mate_seekers }} ({{ item.mate_seekers_read }})</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.individuals, index, 'individuals')">{{ item.individuals }} ({{ item.individuals_read }})</td>
                            <td class="text-caption" :class="getCompClass('messages_by_category',item.shelters, index, 'shelters')">{{ item.shelters }} ({{ item.shelters_read }})</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <!-- <apexchart type="line" :options="users_options" :series="users_series"></apexchart> -->
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3>Premium (FS) </h3>
                    <apexchart type="bar" :options="premium_options" :series="premium_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3>Premium (Stripe) </h3>
                    <apexchart type="bar" :options="premium_stripe_options" :series="premium_stripe_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3>Premium (Cashfree) </h3>
                    <apexchart type="bar" :options="premium_cf_options" :series="premium_cf_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3>Premium (Apple) </h3>
                    <apexchart type="bar" :options="premium_apple_options" :series="premium_apple_series"></apexchart>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3 class="mb-2">Fastspring</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Total</th>
                            <th class="text-left font-weight-medium white--text">Monthly</th>
                            <th class="text-left font-weight-medium white--text">Quarterly</th>
                            <th class="text-left font-weight-medium white--text">Semi-Annual</th>
                            <th class="text-left font-weight-medium white--text">Annual</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">One-Week</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in fastsprings"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('fastsprings',item.total, index, 'total')">{{ item.total }}</td>
                            <td class="text-caption" :class="getCompClass('fastsprings',item.monthly, index, 'monthly')">{{ item.monthly }}</td>
                            <td class="text-caption" :class="getCompClass('fastsprings',item.quarterly, index, 'quarterly')">{{ item.quarterly }}</td>
                            <td class="text-caption" :class="getCompClass('fastsprings',item.semiannual, index, 'semiannual')">{{ item.semiannual }}</td>
                            <td class="text-caption" :class="getCompClass('fastsprings',item.annual, index, 'annual')">{{ item.annual }}</td>
                            <td class="text-caption" :class="getCompClass('fastsprings',item.one_week, index, 'one_week')">{{ item.one_week }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3 class="mb-2">Stripe</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Total</th>
                            <th class="text-left font-weight-medium white--text">Monthly</th>
                            <th class="text-left font-weight-medium white--text">Quarterly</th>
                            <th class="text-left font-weight-medium white--text">Semi-Annual</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Annual</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in stripes"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('stripes',item.total, index, 'total')">{{ item.total }}</td>
                            <td class="text-caption" :class="getCompClass('stripes',item.monthly, index, 'monthly')">{{ item.monthly }}</td>
                            <td class="text-caption" :class="getCompClass('stripes',item.quarterly, index, 'quarterly')">{{ item.quarterly }}</td>
                            <td class="text-caption" :class="getCompClass('stripes',item.semiannual, index, 'semiannual')">{{ item.semiannual }}</td>
                            <td class="text-caption" :class="getCompClass('stripes',item.annual, index, 'annual')">{{ item.annual }}</td>
                            
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>



                <v-col cols="12" md="6" lg="6" class="pa-4" v-if="this.category == 'subscriptions'">
                    <h3 class="mb-2">Apple</h3>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr class="primary">
                            <th class="text-left font-weight-medium white--text" style="border-top-left-radius: 5px;">Date</th>
                            <th class="text-left font-weight-medium white--text">Total</th>
                            <th class="text-left font-weight-medium white--text">Monthly</th>
                            <th class="text-left font-weight-medium white--text">Quarterly</th>
                            <th class="text-left font-weight-medium white--text">Semi-Annual</th>
                            <th class="text-left font-weight-medium white--text" style="border-top-right-radius: 5px;">Annual</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                            v-for="(item,index) in apples"
                            :key="item.date"
                            >
                            <td class="text-caption">{{ item.date }}</td>
                            <td class="text-caption" :class="getCompClass('apples',item.total, index, 'total')">{{ item.total }}</td>
                            <td class="text-caption" :class="getCompClass('apples',item.monthly, index, 'monthly')">{{ item.monthly }}</td>
                            <td class="text-caption" :class="getCompClass('apples',item.quarterly, index, 'quarterly')">{{ item.quarterly }}</td>
                            <td class="text-caption" :class="getCompClass('apples',item.semiannual, index, 'semiannual')">{{ item.semiannual }}</td>
                            <td class="text-caption" :class="getCompClass('apples',item.annual, index, 'annual')">{{ item.annual }}</td>
                            
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

            </v-row>



        </div>

    </v-container>
</template>

<script>
export default {
    name: 'Home',
    mounted() {

        if (!this.$store.getters.isLoggedIn) {
            this.$router.push('/login').catch(err => { });
        }
        else {
            this.report('users');
        }

    },
    components: {

    },

    data() {
        return {
            loading: false,
            total_users: 0,
            total_pets: 0,
            total_premium: 0,
            active_pets: 0,
            category: 'users',
            users:[],
            categories: [],
            verified: [],
            adopters: [],
            messages_by_category: [],
            pets_by_day:[],
            fastsprings: [],
            apples: [],
            stripes: [],



            pets_options: {
                colors : ['#9256fd','#57cb00','#ff0000'],
                chart: {
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                /* stroke: {
                    curve: 'smooth',
                    width: 2
                }, */
                plotOptions: {
                bar: {
                    borderRadius: 5,
                },
                },
                xaxis: {
                    type: "datetime"
                }
            },
            pets_series: [],
            profile_video_series: [],
            dna_reports_series: [],
            pedigree_certificate_series: [],

            video_options: {
                colors : ['#9256fd','#57cb00','#ff0000'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    //curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    type: "datetime"
                }
            },

            dna_options: {
                colors : ['#9256fd','#57cb00','#ff0000'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    //curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    type: "datetime"
                }
            },

            pedigree_options: {
                colors : ['#9256fd','#57cb00','#ff0000'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    //curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    type: "datetime"
                }
            },


            purpose_options: {
                colors : ['#9256fd','#57cb00','#ffb401'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    //curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    type: "datetime"
                }
            },
            purpose_series: [],

            messages_options: {
                colors : ['#9256fd','#ffb401','#57cb00','#16b1ff'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    //curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    type: "datetime"
                }
            },
            messages_series: [],


            premium_options: {
                colors : ['#57cb00','#9c9ea3'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                /* stroke: {
                    curve: 'smooth',
                    width: 2
                }, */
                plotOptions: {
                bar: {
                    borderRadius: 5,
                },
                },
                xaxis: {
                    type: "datetime"
                },

            },
            premium_series: [],

            premium_stripe_options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                /* stroke: {
                    curve: 'smooth',
                    width: 2
                }, */
                plotOptions: {
                bar: {
                    borderRadius: 5,
                },
                },
                xaxis: {
                    type: "datetime"
                },
                colors : ['#57cb00','#9c9ea3'],

            },
            premium_stripe_series: [],

            premium_cf_options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                /* stroke: {
                    curve: 'smooth',
                    width: 2
                }, */
                plotOptions: {
                bar: {
                    borderRadius: 5,
                },
                },
                xaxis: {
                    type: "datetime"
                },
                colors : ['#57cb00','#9c9ea3'],

            },
            premium_cf_series: [],

            premium_apple_options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                  bar: {
                      borderRadius: 5,
                  },
                },
                xaxis: {
                    type: "datetime"
                },
                colors : ['#57cb00','#9c9ea3'],
            },
            premium_apple_series: [],
        }
    },

    filters: {
        abbr: function (num) {
            //if (String(num).length < 7) {
            return Math.floor(num / 1000) + 'K';
            //} else {
            //    return Math.floor(num/1000000) + 'M';
            //}
        }
    },

    methods: {

        formatSeriesData(data) {
            if (!data) {
                return [];
            }
            return data.map(i => {
              return {
                x: i.date,
                y: i.total
              }
            })
        },

        getCompClass(arrayName, currentValue, index, key) {
            if (index + 1 < this[arrayName].length) {
            const nextRowValue = Number(this[arrayName][index + 1][key]);
            const currentNumValue = Number(currentValue);

            // If both values are exactly the same, return 'black'
            if (currentNumValue === nextRowValue) {
            return 'black--text';
            }
            
            // If the current value is greater than the next, return 'green'
            if (currentNumValue > nextRowValue) {
            return 'green--text font-weight-medium';
            }
            
            // If the current value is smaller than the next, return 'red'
            if (currentNumValue < nextRowValue) {
            return 'red--text font-weight-medium';
            }
        }

        // Default return for cases where there's no next row to compare
        return 'black--text';
        },

        report(category = 'users') {
            this.category = category;
            this.fetchData();
        },

        fetchData() {
            this.loading = true;
            let category = this.category
            this.$store.dispatch('getStats', { category }).then((data) => {
                this.loading = false;
                //console.log(data);

                if (this.category == 'users') {

                    this.users = data.users;
                    this.categories = data.categories;
                    this.verified = data.verified;
                    this.adopters = data.adopters;

                    this.total_users = data.total_users;
                    this.total_pets = data.total_pets;
                    this.active_pets = data.active_pets;
                    this.total_premium = data.total_premium;

                    /* const user_email_series = this.formatSeriesData(data.user_email);
                    const user_email_verified_series = this.formatSeriesData(data.user_email_verified);

                    const user_phone_series = this.formatSeriesData(data.user_phone);
                    const user_phone_verified_series = this.formatSeriesData(data.user_phone_verified); */
                }


                if (this.category == 'pets') {

                    this.total_pets = data.total_pets;
                    this.active_pets = data.active_pets;

                    this.pets_by_day = data.pets_by_day;

                    /* const profile_video_total = this.formatSeriesData(data.profile_videos);
                    const profile_video_verified = this.formatSeriesData(data.profile_videos_verified);
                    const profile_video_rejected = this.formatSeriesData(data.profile_videos_rejected);

                    const dna_reports_total = this.formatSeriesData(data.dna_reports);
                    const dna_reports_verified = this.formatSeriesData(data.dna_reports_verified);
                    const dna_reports_rejected = this.formatSeriesData(data.dna_reports_rejected);

                    const pedigree_certificates_total = this.formatSeriesData(data.pedigree_certificates);
                    const pedigree_certificates_verified = this.formatSeriesData(data.pedigree_certificates_verified);
                    const pedigree_certificates_rejected = this.formatSeriesData(data.pedigree_certificates_rejected);

                    this.profile_video_series = [
                        {
                          name: "Total",
                          data: profile_video_total
                        },
                        {
                          name: "Verified",
                          data: profile_video_verified
                        },
                        {
                          name: "Rejected",
                          data: profile_video_rejected
                        },
                    ];
                    this.dna_reports_series = [
                        {
                          name: "Total",
                          data: dna_reports_total
                        },
                        {
                          name: "Verified",
                          data: dna_reports_verified
                        },
                        {
                          name: "Rejected",
                          data: dna_reports_rejected
                        },
                    ];
                    this.pedigree_certificate_series = [
                        {
                          name: "Total",
                          data: pedigree_certificates_total
                        },
                        {
                          name: "Verified",
                          data: pedigree_certificates_verified
                        },
                        {
                          name: "Rejected",
                          data: pedigree_certificates_rejected
                        },
                    ];

                    const pets_dogs_series = this.formatSeriesData(data.dogs);
                    const pets_cats_series = this.formatSeriesData(data.cats);
                    const pets_others_series = this.formatSeriesData(data.others);

                    this.pets_series = [
                        {
                            name: "Dogs",
                            data: pets_dogs_series
                        },
                        {
                            name: "Cats",
                            data: pets_cats_series
                        },
                    ];

                    const purpose_breeding_series = this.formatSeriesData(data.breeding);
                    const purpose_adoption_series = this.formatSeriesData(data.adoption);
                    const purpose_sale_series = this.formatSeriesData(data.sale);

                    this.purpose_series = [
                        {
                            name: "Breeding",
                            data: purpose_breeding_series
                        },
                        {
                            name: "Adoption",
                            data: purpose_adoption_series
                        },
                        {
                            name: "Sale",
                            data: purpose_sale_series
                        }
                    ]; */
                }

                if (this.category == 'messages') {

                    this.messages_by_category = data.messages_by_category;

                    /* const message_verified_series = this.formatSeriesData(data.messages_verified);
                    const message_series = this.formatSeriesData(data.messages);
                    const conversation_series = this.formatSeriesData(data.conversations);
                    const read_series = this.formatSeriesData(data.read);

                    this.messages_series = [
                        {
                            name: "Messages",
                            data: message_series
                        },
                        {
                            name: "Conversations",
                            data: conversation_series
                        },
                        {
                            name: "Read",
                            data: read_series
                        },
                        {
                            name: "Verified",
                            data: message_verified_series
                        },
                    ]; */
                }

                if (this.category == 'subscriptions') {

                    this.total_premium = data.total_premium;

                    this.fastsprings = data.fast_springs;
                    this.apples = data.apples;
                    this.stripes = data.stripes;

                    const premiums_series = this.formatSeriesData(data.premium);
                    const churn_series = this.formatSeriesData(data.churn);

                    this.premium_series = [
                        {
                            name: "Premium",
                            data: premiums_series
                        },
                        {
                            name: "Churn",
                            data: churn_series
                        }
                    ];

                    const premiums_stripe_series = this.formatSeriesData(data.premium_stripe);
                    const churn_stripe_series = this.formatSeriesData(data.churn_stripe);

                    this.premium_stripe_series = [
                        {
                            name: "Premium",
                            data: premiums_stripe_series
                        },
                        {
                            name: "Churn",
                            data: churn_stripe_series
                        }
                    ];

                    const premiums_cf_series = this.formatSeriesData(data.premium_cf);


                    this.premium_cf_series = [
                        {
                            name: "Premium",
                            data: premiums_cf_series
                        }
                    ];

                    const premiums_apple_series = this.formatSeriesData(data.premium_apple);
                    const churn_apple_series = this.formatSeriesData(data.churn_apple);
                    this.premium_apple_series = [
                        {
                            name: "Premium",
                            data: premiums_apple_series
                        },
                        {
                          name: "Churn",
                          data: churn_apple_series
                        }
                    ];
                }
            })
                .catch(err => {
                    //console.log(err)
                    this.loading = false;
                    //this.snackbar_text = "Oops, Couldn't get data. Please try again.";
                    //this.snackbar = true;
                });
        },
    },
}
</script>
